<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center" v-if="!submited">
          <!--  -->
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Inicio Sesión</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form ref="form" v-model="valid" :lazy-validation="false">
                  <v-text-field
                    label="Usuario"
                    name="login"
                    prepend-icon="mdi-account"
                    type="text"
                    :rules="anyRules"
                    v-model="user.username"
                  />
                  <v-text-field
                    id="password"
                    label="Contraseña"
                    name="password"
                    prepend-icon="mdi-lock-outline"
                    type="password"
                    :rules="anyRules"
                    v-model="user.password"
                    @keyup.enter="submit()"
                  />
                </v-form>
              </v-card-text>
              <v-card-text class="mt-0 pt-0">
                <v-alert
                  type="warning"
                  v-for="(message, index) in messages"
                  :key="index"
                  >{{ message }}</v-alert
                >
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="primary" @click="submit" :disabled="!valid"
                  >Iniciar Sesión</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row align="center" justify="center" v-else>
          <v-progress-circular
            indeterminate
            :size="100"
            :width="10"
            color="blue"
          ></v-progress-circular>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import rules from "@/utils/form.rules";

export default {
  data: () => ({
    //from state
    dialog: false,
    enabled: false,
    submited: false,
    valid: false,
    messages: [],
    //Form data
    user: {
      username: "",
      password: "",
    },
    // rules
    anyRules: [rules.required, rules.minLength(4)],
  }),
  methods: {
    submit() {
      this.messages = [];
      if (this.$refs.form.validate()) {
        this.submited = true;
        this.$store.dispatch("auth/login", this.user).then(
          () => {
            this.$router.push("/");
          },
          (error) => {
            this.submited = false;
            try {
              if (error.non_field_errors.length > 0)
                this.messages = error.non_field_errors;
            } catch (e) {
              this.messages = ["Servicio no disponible"];
            } finally {
              console.log(error);
            }
          }
        );
      }
    },
  },
  props: {
    source: String,
  },
};
</script>
